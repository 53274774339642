<template>
  <div class="selector-list-item">
    <a id='productID_${item.id}'
       @click="select()"
       class='select_list ThemeAppListItem'
       :class="{ selected: selected }"
       v-html="contents"/>
    <a v-if="listType===DataTypes.customers" class="more" @click="showCustomer">
      <icon name="fa-chevron-right"/>
    </a>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import moment from 'moment';
  import translate from '@/components/Mixins/Translate';
  import DataTypes from '../../enums/data-types';

  export default {
    name: 'selector-list-item',
    props: ['item', 'initiallySelected', 'listType', 'sortBy'],
    emits: ['select', 'deselect'],
    mixins: [translate],
    data() {
      return {
        selected: this.initiallySelected,
        renderers: {
          [DataTypes.products]: {
            name: (item) => `${item.name}<p>${item.category}</p>`,
            category: (item) => `${item.name}<p>${item.category}</p>`,
            sortIndex: (item) => `${item.name}<p>${item.category}</p>`,
          },
          [DataTypes.campaigns]: {
            name: (item) => item.name,
            periodFrom: (item) => (item.periodFrom === null || item.periodFrom === undefined
              ? item.name
              : `${item.name}<p>${moment.utc(item.periodFrom).local().calendar()}
 - ${moment.utc(item.periodTo).local().calendar()}</p>`),
          },
          [DataTypes.customers]: {
            DistKM: (item) => `${item.name}<p><b>${Math.round(item.DistKM || 0)} Kilometer</b>
${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
            // eslint-disable-next-line max-len
            name: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
            // eslint-disable-next-line max-len
            city: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
            // eslint-disable-next-line max-len
            address: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
            // eslint-disable-next-line max-len
            postalCode: (item) => `${item.name}<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          },
        },
        DataTypes,
      };
    },
    computed: {
      contents() {
        return this.renderers[this.listType][this.sortBy](this.item);
      },
    },
    methods: {
      select() {
        this.selected = !this.selected;
        if (!this.selected) {
          this.$emit('deselect', this.item);
        } else {
          this.$emit('select', this.item);
        }
      },
      showCustomer() {
        // Open customer page for that customer
        console.log('showCustomer', this.item);
        const customer = this.item;
        const CustomerPage = defineAsyncComponent(() => import('../Customer/CustomerPage'));
        this.$store.commit('pushPopup', {
          component: CustomerPage,
          title: customer.name,
          params: {
            customer,
          },
          direction: 'left',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selector-list-item {
    position: relative;

    .more {
      position: absolute;
      /*height: 60px;*/
      width: 60px;
      /*background-color: #00c1ff;*/
      top: 0;
      bottom: 0;
      right: -20px;
      border-left: 1px solid #eeeeee;

      i {
        position: absolute;
        right: 20px;
        top: calc(50% - 10px);
        font-size: 1.5em;
        color: #d0d0d0;
      }
    }
  }
</style>
