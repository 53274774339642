<template>
  <div class="selectorlist">
    <dl>
      <dt class="ThemeBackgroundColor" v-if="showCustomTopbar">
        <span class="title">{{ title }}</span>
        <a @click="close_list" class="saveProducts">{{ x('save') }}</a>
      </dt>
      <dd class="search">
        <input title="search" type="text" class="lsc-input lsc-rounded list_searchbar"
               v-model="searchText" :placeholder='x("searchDots")'><i class='fa fa-search'></i>
      </dd>
      <dd class="sort">
        Sorteret efter: <a @click="toggleSortOptions()" class="defaultSort">{{ x(sortLabel) }}</a><a
        @click="toggleSortDirection()" class="sort_direction"><i
        class="fa"
        :class="{ 'fa-sort-amount-asc': sortAscending, 'fa-sort-amount-desc': !sortAscending }"
      ></i></a>

        <ul class='sortList' v-show="sortOptionsVisible && hasLoaded">
          <template v-if="listState.listType===DataTypes.customers">
            <li v-if="hasPosition"><a @click="setSortProperty('DistKM')">{{ x('distance') }}</a>
            </li>
            <li><a @click="setSortProperty('name')">{{ x('name') }}</a></li>
            <li><a @click="setSortProperty('city')">{{ x('city') }}</a></li>
            <li><a @click="setSortProperty('address')">{{ x('address') }}</a></li>
            <li><a @click="setSortProperty('postalCode')">{{ x('postalCode') }}</a></li>
          </template>
          <template v-if="listState.listType===DataTypes.campaigns">
            <li><a @click="setSortProperty('name')">{{ x('name') }}</a></li>
            <li><a @click="setSortProperty('periodFrom')">{{ x('period') }}</a></li>
          </template>
          <template v-if="listState.listType===DataTypes.products">
            <li><a @click="setSortProperty('sortIndex')">{{ x('default') }}</a></li>
            <li><a @click="setSortProperty('name')">{{ x('name') }}</a></li>
            <li><a @click="setSortProperty('category')">{{ x('category') }}</a></li>
          </template>
        </ul>
      </dd>
      <dd class="content">
        <loading-sign v-if="!hasLoaded"/>
        <template v-if="listState.listType === DataTypes.customers && hasLoaded && !isExternalUser">
          <a @click="showManualCustomerUI=true" v-if="!showManualCustomerUI"
             class='select_list add'>{{ x('addShop') }}</a>
          <create-manual-customer v-if="showManualCustomerUI"
                                  @created="manualCustomerAddedAndSelected"
                                  @close="showManualCustomerUI=false" />
        </template>
        <a v-if="enableNoCustomer && hasLoaded && !isExternalUser" class='select_list no-customer'
           @click="selectNoCustomer()">{{ x('noCustomer') }}</a>
        <selector-list-item
          v-for="item in shownItems"
          :item="item"
          :key="item.id"
          :list-type="listState.listType"
          :sort-by="sortBy"
          :initially-selected="isItemSelected(item)"
          @select="itm => selectItem(itm)"
          @deselect="itm => deselectItem(itm)">
        </selector-list-item>
        <button v-if="showLoadMoreButton" @click='showMoreItems'
                class='lsc-button lsc-rounded-10 grey b_LoadMore'>{{ x('loadMore') }}
        </button>
      </dd>
    </dl>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'vuex';
  import {
    take, remove, sortBy, reverse,
  } from 'lodash';
  import { getDistanceFromLatLongInKm } from '@/classes/location-helper';
  import { httpGet } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import Events from '@/enums/event-names';
  import translate from '../Mixins/Translate';
  import SelectorListItem from './SelectorListItem';
  import LoadingSign from '../LoadingSign';
  import DataTypes from '../../enums/data-types';

  export default {
    name: 'selector-list',
    props: {
      type: {
        type: String,
        required: true,
      },
      callback: Function,
      selectMultiple: {
        type: Boolean,
        default: false,
      },
      initialSelectedItems: {
        type: Array,
        default() { return []; },
      },
      title: String,
      selected: Array,
      preloadedItems: Array,
    },
    emits: [ComponentEventNames.didLoad],
    components: {
      'create-manual-customer': defineAsyncComponent(() => import('./CreateManualCustomer')),
      'selector-list-item': SelectorListItem,
      'loading-sign': LoadingSign,
    },
    mixins: [translate],
    data() {
      return {
        showManualCustomerUI: false,
        sortOptionsVisible: false,
        allItems: [],
        selectedItems: this.initialSelectedItems,
        sortBy: '',
        sortAscending: true,
        hasLoaded: false,
        listState: {
          listType: '', /** Indicates the type of data that is shown */
          amount: 0, /** The number of items being shown in the list right now. */
        },
        searchText: '',
        labels: {
          [DataTypes.products]: {
            name: 'name',
            category: 'category',
          },
          [DataTypes.campaigns]: {
            name: 'name',
            periodFrom: 'period',
          },
          [DataTypes.customers]: {
            DistKM: 'distance',
            name: 'name',
            city: 'city',
            address: 'address',
            postalCode: 'postalCode',
          },
        },
        noCustomerCustomer: null, /** A special customer that is used as the "no customer" item. */
        DataTypes,
      };
    },
    computed: {
      showLoadMoreButton() {
        return this.listState.amount < this.filteredItems.length;
      },
      filteredItems() {
        if (this.searchText === '') {
          return this.allItems;
        }
        const lowercaseFilter = this.searchText.toLowerCase();
        return this.allItems.filter((v) => {
          if (v.name && v.name.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.address && v.address.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.city && v.city.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.postalCode && v.postalCode.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          if (v.extra1 && v.extra1.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
          return false;
        });
      },
      shownItems() {
        return take(this.filteredItems, this.listState.amount);
      },
      showCustomTopbar() {
        return this.selectMultiple === true;
      },
      sortLabel() {
        if (this.sortBy === '' || this.sortBy === 'sortIndex') return 'standard';
        if (this.labels[this.listState.listType] === undefined) return this.sortBy;
        if (this.labels[this.listState.listType][this.sortBy] === undefined) return this.sortBy;
        return this.labels[this.listState.listType][this.sortBy];
      },
      enableNoCustomer() {
        console.log('this.config.obj_texts: ', this.config.obj_texts);
        return this.type === DataTypes.customers && this.config.obj_texts.EnableNoCustomer;
      },
      hasPosition() {
        return this.location !== null;
      },
      isExternalUser() {
        return this.user.userRole === 'external';
      },
      ...mapState(['user', 'config']),
      ...mapState('LocationStore', ['location']),
      ...mapState('CachingStore', {
        cachedProducts: (state) => state.products,
        cachingPosition: (state) => state.cachingPosition,
        cachedCustomers: (state) => state.customers,
        noCustomer: (state) => state.noCustomer,
      }),
    },
    async mounted() {
      this.$emit(ComponentEventNames.didLoad);
      switch (this.type) {
        case DataTypes.customers:
          await this.openCustomerList();
          break;
        case DataTypes.products:
          await this.openProductList();
          break;
        case DataTypes.campaigns:
          await this.openCampaignList();
          break;
        default:
          console.warn(`Unknown type '${this.type}' specified in params for selector list.`);
          break;
      }
      this.registerEventHandlers();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        if (this.type === DataTypes.customers) {
          this.$bus.on(Events.customerDeleted, this.onCustomerDeleted);
        }
      },
      unregisterEventHandlers() {
        if (this.type === DataTypes.customers) {
          this.$bus.off(Events.customerDeleted, this.onCustomerDeleted);
        }
      },
      async openCampaignList() {
        this.listState.listType = DataTypes.campaigns;
        this.sortAscending = true;
        this.sortBy = 'periodFrom';

        // For campaigns, data is already loaded by EditVisit and provided via popup params.
        this.allItems = this.preloadedItems;
        this.resetAmount();
        this.hasLoaded = true;
      },
      async openProductList() {
        this.listState.listType = DataTypes.products;
        this.sortAscending = true;
        this.sortBy = 'sortIndex';

        if (this.cachedProducts === null) {
          const data = await httpGet('products');
          this.$store.commit('CachingStore/setProductCache', data);
          this.allItems = this.sortList(data);
        } else {
          this.allItems = this.sortList(this.cachedProducts);
        }
        this.resetAmount();
        this.hasLoaded = true;
      },
      resetAmount() {
        this.listState.amount = Math.min(100, this.filteredItems.length);
      },
      async openCustomerList() {
        this.listState.listType = DataTypes.customers;
        this.sortAscending = true;
        this.sortBy = this.location == null ? 'name' : 'DistKM';

        // TODO: Use LocationStore
        let locationHasChanged = false;
        if (this.cachingPosition != null && this.location !== null) {
          const distanceInMeter = getDistanceFromLatLongInKm(
            this.location,
            this.cachingPosition,
          ) * 1000;
          locationHasChanged = distanceInMeter > 50;
        }

        if (this.cachedCustomers === null || locationHasChanged) {
          let url = '';
          if (this.location == null) {
            if (this.isExternalUser) {
              url = `customers/byvisitplansforuser/${this.user.id}`;
            } else {
              url = 'customers';
            }
          } else {
            const { latitude: lat, longitude: lng } = this.location;
            if (this.isExternalUser) {
              url = `customers/byvisitplansforuserbydistance?lat=${lat}&long=${lng}&userid=${this.user.id}`;
            } else {
              url = `customers/bydistance?lat=${lat}&long=${lng}&channelonly=true`;
            }
          }
          const loadedCustomers = await httpGet(url);

          if (!loadedCustomers.error) {
            if (this.type === DataTypes.customers && this.enableNoCustomer) {
              // Extract the "No Customer" customer into its own variable.
              const noCustomerId = this.config.obj_texts.NoCustomerId;
              const noCustomer = await httpGet(`customers/${noCustomerId}`);
              if (!noCustomer) {
                console.error('EnableNoCustomer feature is turned on, but no "no customer" customer was found.');
              }
              remove(loadedCustomers, (item) => item.id.toLowerCase() === noCustomerId.toLowerCase());
              this.noCustomerCustomer = noCustomer;
            }

            this.$store.commit('CachingStore/setNoCustomer', this.noCustomerCustomer);
            this.$store.commit('CachingStore/setCustomerCacheAndPosition', {
              customers: loadedCustomers,
              cachingPosition: this.location,
            });

            const threeDaysInMilliseconds = 1000 * 60 * 60 * 24 * 3;
            setTimeout(() => {
              this.$store.commit('CachingStore/setNoCustomer', this.noCustomerCustomer);
              this.$store.commit('CachingStore/setCustomerCacheAndPosition', { customers: null, position: null });
            }, threeDaysInMilliseconds);

            this.listState.amount = Math.min(100, loadedCustomers.length);
            // Use basic sorting from Stored Procedure - previous: this.sortList(data);
            this.allItems = this.sortList(loadedCustomers);
            this.resetAmount();
          } else {
            // eslint-disable-next-line no-alert
            alert(this.x('customerRetrievalFailedMessage'));
          }
        } else {
          // Use basic sorting from Stored Procedure - previous:this.sortList(this.cachedCustomers);
          this.allItems = this.sortList(this.cachedCustomers);
          this.noCustomerCustomer = this.noCustomer;
          this.resetAmount();
        }
        this.hasLoaded = true;
      },
      manualCustomerAddedAndSelected(customer) {
        console.log('Manual customer added and selected', customer);
        this.$store.commit('CachingStore/addManualCustomerToCache', customer);
        this.allItems.push(customer);
        this.selectItem(customer);
      },
      sortList(list) {
        // If we sort by sortIndex or name, the sort is by that property alone.
        // If sortBy is something else, we start by sorting by that property, then by name.
        // NOTE: We assume that all data has a 'name' property. This might fail, if they don't.
        if (this.sortBy === 'sortIndex' || this.sortBy === 'name') {
          console.log('Sorting by ', this.sortBy);
          return sortBy(list, this.sortBy);
        }
        console.log(`Sorting by ${this.sortBy}, then by name.`);
        return sortBy(list, [this.sortBy, 'name']);
      },
      selectItem(item) {
        if (this.selectMultiple) {
          this.selectedItems.push(item);
        } else {
          this.selectedItems = [item];
          this.close_list();
        }
      },
      deselectItem(item) {
        if (this.selectMultiple) {
          const idx = this.selectedItems.indexOf(item);
          this.selectedItems.splice(idx, 1);
        } else {
          this.selectedItems = [item];
          this.close_list();
        }
      },
      isItemSelected(item) {
        return this.selectedItems.some((s) => s.id === item.id);
      },
      toggleSortDirection() {
        console.log('toggleSort');
        this.sortAscending = !this.sortAscending;
        this.allItems = reverse(this.allItems);
        // For some reason, the reversing of the array does not trigger reactive updates,
        // that is why we push and pop an item.
        this.allItems.push({});
        this.allItems.pop();
      },
      setSortProperty(propName) {
        console.log('Prop name: ', propName);
        this.sortBy = propName;
        this.allItems = this.sortList(this.allItems);
        this.toggleSortOptions();
      },
      showMoreItems() {
        this.listState.amount = Math.min(this.listState.amount + 100, this.filteredItems.length);
      },
      toggleSortOptions() {
        this.sortOptionsVisible = !this.sortOptionsVisible;
      },
      close_list() {
        if (typeof this.callback === 'function') {
          this.callback(this.selectedItems);
        }
        this.$store.commit('popPopup');
      },
      selectNoCustomer() {
        this.selectItem(this.noCustomerCustomer);
      },
      onCustomerDeleted(params) {
        this.allItems = this.allItems.filter((item) => item.id !== params.customerId);
        this.$store.commit('CachingStore/setCustomerCache', this.allItems);
      },
    },
  };
</script>

<style lang="scss" src="./selectorlist.scss"></style>
